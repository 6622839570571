@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

html,
body {
  font-family: Poppins;
  font-size: 14px;
  color: white;
}

.is-tabs .under-tabs{
  background-color: #17171a !important;
}

html {
  background: linear-gradient(left, #99a, #eee, #99a);
}

html {
  background: linear-gradient(left, #99a, #eee, #99a);
}

.page {
  margin: 0 auto 0 -40px;
  text-align: center;
  padding: 1em 0;
}

.scroll {
  height: 300px;
  width: 40px;
  overflow-y: scroll;
  display: inline-block;
}

.scroll .inner {
  height: 300%;
  width: 100%;
  content: ".";
}

.scroll--simple::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroll--simple::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

.scroll--simple::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

.scroll--simple::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.scroll--simple::-webkit-scrollbar-thumb:active {
  background: rgba(0, 0, 0, 0.9);
}

.scroll--grey::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll--grey::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.05);
}

.scroll--grey::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #999, #888);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.8);
}

.scroll--grey::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(left, #888, #777);
}

.scroll--white::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.scroll--white::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
}

.scroll--white::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #fff, #e4e4e4);
  border: 1px solid #aaa;
}

.scroll--white::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

.scroll--white::-webkit-scrollbar-thumb:active {
  background: linear-gradient(left, #22add4, #1e98ba);
}

.scroll--square::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scroll--square::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}

.scroll--square::-webkit-scrollbar-thumb {
  background: linear-gradient(left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15));
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.scroll--square::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4));
}

.scroll--black::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scroll--black::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #444;
  box-shadow: 0 0 1px 1px #111, inset 0 0 4px rgba(0, 0, 0, 0.3);
}

.scroll--black::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #3e3e3e, #111, #000);
  box-shadow: inset 0 0 1px 1px #646464;
}
.scroll--numbers::-webkit-scrollbar {
  overflow:hidden;
  width: 4px;
  height: 4px;
}

.scroll--numbers::-webkit-scrollbar-track {
  background: #2C2D2F;
}

.scroll--numbers::-webkit-scrollbar-thumb {
  background: #4D4C50;
}

.scroll--numbers::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(left, black, black);
}

.scroll--sublime::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scroll--sublime::-webkit-scrollbar-track {
  border-radius: 10px;
  background: linear-gradient(left, #212121, #323232);
  box-shadow: 0 0 1px 1px #111;
}

.scroll--sublime::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(left, #4a4a4a, #404040);
  box-shadow: inset 0 0 1px 1px #191919;
}

.scroll--sublime::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(left, #555555, #4e4e4e);
}

ul {
  background: #303030 !important;
}

input,
label {
  color: white !important;
}

.peer:disabled~.peer-disabled\:before\:border-transparent::before {
  border-color: rgb(176 190 197 / var(--tw-border-opacity)) !important;
}

.peer:disabled~.peer-disabled\:before\:border-transparent::after {
  border-color: rgb(176 190 197 / var(--tw-border-opacity)) !important;
}

.peer:focus~.peer-focus\:after\:border-r-2::after {
  border-color: rgb(176 190 197 / var(--tw-border-opacity)) !important;
}

.peer:focus~.peer-focus\:after\:border-r-2::before {
  border-color: rgb(176 190 197 / var(--tw-border-opacity)) !important;
}


.disabled\:bg-blue-gray-50:disabled {
  background-color: #00000090 !important;
  border-width: 1px !important;
  cursor: not-allowed;
}

.text-blue-gray-700 {
  color: white !important;
}

#static-page h1 {
  font-size: 1.5rem;
  font-weight: bold;
}

#static-page>div>div>div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#static-page>div>div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


#static-page>div> {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


#static-page {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#static-page ul {
  background: none !important;
  list-style: circle;
  padding-left: 40px;
}

#static-page h2 {
  font-size: 1.2rem;
  font-weight: 600;
}

body {
  padding-bottom: env(safe-area-inset-bottom);
}

body.prevent-scroll {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.safe-area {
  padding-bottom: env(safe-area-inset-bottom);
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

input:checked {
  background: var(--pink-violetRed) !important;
}

.peer:checked~.peer-checked\:border-green-500 {
  border-color: var(--pink-violetRed);
}

h2::first-letter {
  text-transform: uppercase;
}

.fa-angel {
  background-image: url("./Component/Filters/svg/angel.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 1em;
  width: 1.5em;
  display: inline-block;
}

.fa-heartbeat {
  background-image: url("./Component/Filters/svg/cutie.svg");
  background-position: center;
  background-repeat: no-repeat;
  height: 1em;
  width: 1.5em;
  display: inline-block;
}

.gradient-fire {
  background: linear-gradient(to top right, #FF5D14, #F39B0D);
}

.gradient-rocket {
  background: linear-gradient(to top right, #0099C7, #02D5DF);
}

.gradient-user {
  background: linear-gradient(to top right, #F0261F, #EF5017);
}

.gradient-eye {
  background: linear-gradient(to top right, #3553A9, #6E6BD2);
}

.gradient-camera {
  background: linear-gradient(to top right, #724C87, #E14571);
}

.gradient-star {
  background: linear-gradient(to top left, #8E704C, #F3D1A6);
}

.gradient{
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;
}

.gradient>rect{
  color: #242526;
}

*{
  user-select: none;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
}
/*SAFAR <= 15 FIX */
input,textarea {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
.fa-layers>svg{
  position: relative !important;
}

.max-h-viewer{
  max-height: calc(100dvh - 4rem);
}

img,video{
  pointer-events: none;
}

.interact{
  pointer-events: all;
}

.form-control{
  width: 100% !important;
  background-color: transparent !important;
}

.country.highlight{
  color: black !important;
}

.flag-dropdown {
  background-color: transparent !important;
}

.phone-error .flag-dropdown, .phone-error .form-control{
  border-color: red !important;
}

.fade-out{
  transition: opacity 5s cubic-bezier(1,0,.8,1);
  opacity: 0;
}

input:disabled,textarea:disabled{
  opacity: 0.7;
  cursor: not-allowed;
}

.bshadow{
  width: 0px; 
  height: 0px;
  border-radius: 50%;
  box-shadow: 0 0 80px 40px rgba(255, 255, 255, 0.4);
}

.credit-card .label{
  color: var(--pink-violetRed) !important;
}

.content-data-replicated::after{
  content: attr(data-replicated-value) " " !important;
  grid-area: 1 / 1 / 2 / 2;
  @apply py-3 px-4 pr-14;
}

.content-data-replicated>textarea{
  grid-area: 1 / 1 / 2 / 2;
}